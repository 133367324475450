import { Ui } from '@elrondnetwork/dapp-utils';
import * as Dapp from '@elrondnetwork/dapp';
import { getNfts } from '../../apiEndpoints';
import { getRemainingNfts } from '../../apiEndpoints';
import { useEffect, useRef, useState } from 'react';
import { NFTCollection } from '../../config';
import { shortenWalletAddress } from '../../utils';
import gears from '../../static/media/gears.png';
import balance from '../../static/media/money-bag.png';


const Staking = () => {


return (

        <div id="stakcontainer">

            <div id="work">
           
             <div id="workblok">

                <div id="work1">Our staking service is available on XOXNO marketplace</div>

                 <a style={{textDecoration: 'none'}} href="https://xoxno.com/staking"><div id="work2">Stake</div></a>
            </div>

            </div>

        </div>


     );
  };
  
  export default Staking;