import { Ui } from '@elrondnetwork/dapp-utils';
import * as Dapp from '@elrondnetwork/dapp';
import { useHistory } from 'react-router-dom';
import { getNfts } from '../../apiEndpoints';
import { countNfts } from '../../apiEndpoints';
import $ from 'jquery';
import { InfosCollection } from '../../apiEndpoints';
import { useEffect, useRef, useState } from 'react';
import balance from '../../static/media/currency.png';
import home from '../../static/media/home.png';
import axios from 'axios';




const AccountTab = () => {

  const dappLogout = Dapp.useLogout();
  const history = useHistory();

  const {account, address, explorerAddress} = Dapp.useContext();

  const [nfts, setNftsList] = useState([]);
  const [count, setCount] = useState(0);
  const [infosco, setInfosco] = useState(0);
  const [smallwallet, setSmallWallet]= useState('');
  const [loading, setLoading] = useState(false);
  const mounted = useRef(true);


  if (address == null)

 {

  dappLogout({ callbackUrl: `${window.location.origin}/`});
  history.push('/');
   
  }


    useEffect(() =>
  
  {

    setLoading(true);

    const fetchData = async () => {

             const resultnfts = await axios.get(getNfts(address))
             

             if (resultnfts.data != 0)

             {

             const resultnfts = await axios.get(getNfts(address))
             const resultcount =  await axios.get(countNfts(address))
             const resultstuffcount = await axios.get(InfosCollection(address))

              axios.all([resultnfts,resultcount,resultstuffcount]).then(
              axios.spread((...allData) => 
              {
                  const allDataNfts = allData[0].data;
                  const allDataCount = allData[1].data;
                  const allDataStuff = allData[2].data.count;

                 
                   setNftsList(allDataNfts)
                   setCount(allDataCount)
                   setInfosco(allDataStuff)

                   if (mounted.current) 
                   {setLoading(false);}
             
              
              })) 

              }
              
              else {

                setLoading(false);

                await axios.get(countNfts(address)).then(res => {const allnfts = res.data;
                setCount(allnfts)})

                console.log('Nothing');

              }


      };
        
  
   

    fetchData() 

    if (address != null)

    {
      const shortenWalletAddress = (address: string, charsAmount = 6) => {
        const firstPart = address.substring(0, charsAmount);
        const lastPart = address.substring(
          address.length - charsAmount,
          address.length
        );
        
        return `${firstPart}...${lastPart}`;
      };
    
      setSmallWallet(shortenWalletAddress(address))
    
    }



  }, [address])

  return (


    <div id="maincontainerstuff">

      <div id="flexcontainerstuffcard">

        <div className="stuffadress">

          <div className="titrewallet">

            Your wallet address:

          </div>

          <div className="containerbalance">

            <div><img width={48} src={home}/></div>
            <p className="text1"><a href={`${explorerAddress}accounts/${address}`}>{smallwallet}</a></p>

          </div>

        </div>




        <div className="stuffbalance">

          <div className="titrewallet">

            Your wallet balance:

          </div>
          
            
            <div className="containerbalance">

              <div><img width={48} src={balance}/></div> 
              <p className="text1"><Ui.Denominate value={account.balance} erdLabel="EGLD" /></p>
            </div>

        </div>

      </div>

      <div className="infosnfts">

         <div className="titrewallet">Infos:</div>

  
         <p>You have {count} NFTs on the Elrond Network and {infosco} Arcadia NFTs</p>
     
      
      </div>

     

      <div className="h2">My Elrond Arcadia NFTs: </div> 
 

    

 
  
      <div id="collectioncontainer">

        {loading ? (


          <div className="loader"></div>


        ) : (

       

          nfts.map((nft: any, index: number) => (

              <a className="lien" href={`${explorerAddress}nfts/${nft.identifier}`}>
                
                <div id="collectioncard" key={index}>

                  <div id="cardimg">

                   <img src={atob(nft.uris[0])} alt="NFT image" width={200}/>

                  </div>
                
                  <div className="idnft">{nft.name}</div>

                  <div id="nftidentifier">

                    {nft.identifier}

                  </div>

              </div></a>
              
          )))}

          {!loading && nfts.length === 0 && <div id="nonft">No NFT here...</div>}

      </div>


    </div>

  );

   
};

export default AccountTab;
