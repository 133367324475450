import * as Dapp from '@elrondnetwork/dapp';

export const walletConnectBridge ='https://bridge.walletconnect.org';
export const walletConnectDeepLink = 'https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet.dev&link=https://maiar.com/';
export const NFTCollection = 'FOND-6c1c19';
export const NFTContract = 'erd1qqqqqqqqqqqqqpgqmyvwzwprvmeq45fpappxt24k2cf7awv6zsyqx7drdt';

export const network: Dapp.NetworkType = {
  id: "mainnet",
  name: "Mainnet",
  egldLabel: "EGLD",
  walletAddress: "https://wallet.elrond.com",
  apiAddress: "https://api.elrond.com",
  gatewayAddress: "https://gateway.elrond.com",
  explorerAddress: "http://explorer.elrond.com/",
};